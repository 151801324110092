import http from './http';
const { get, post } = http;
// 2023.5.30 新增 提现白名单， 实名认证

// 实名认证列表

export const getRealList = params => {
  return get('/appUser.realList', params);
};

// 清除实名认证
export const clearReal = id => {
  return post('/appUser.clearReal', { id });
};

// 获取提现白名单列表

export const getWhiteList = params => {
  return get('/withdraw.getWhiteList', params);
};

// 添加白名单

export const addWhite = user_id => {
  return post('/withdraw.addWhite', { user_id });
};

// 删除白名单

export const delWhite = id => {
  return post('/withdraw.delWhite', { id });
};
